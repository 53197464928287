<template>
    <div class="signup position-relative">
        <TitleHeader :title="title" :hide_profile="true" />

        <div class="container">
            <div v-if="step == 0">
                <!-- <div class="text-end small text-dark text-opacity-50 mb-2">
                    <span>* 필수 항목</span>
                </div> -->
                <div>
                        <div class="form-group mt-3">
                            <label class="small text-body text-opacity-50">* 이메일</label>
                            <div class="input-group">
                                
                                <input class="form-control border-gray-400 py-px-12" type="email" required placeholder="이메일" v-model="email" :disabled="CheckEmailDisable()">
                                <div class="certi_btn btn border border-gray-400 flex-shrink-0 small text-opacity-50 border-start-0" @click="SendEmailAuth()" v-if="email_certifi_chk !=true">인증코드 전송</div>
                                <div class="certi_btn btn border border-gray-400 flex-shrink-0 small text-opacity-50 border-start-0" @click="ResetEmail()" v-if="email_certifi_chk ==true">재입력 하기</div>
                            </div>
                            <div class="alert alert-success small p-2 lh-1 mt-2" role="alert" v-if="email_certifi_send_chk==true && email_send_able ==true">
                                <small>이메일로 인증번호를 발송했습니다.<br>아래 입력칸에 인증번호를 입력해 주십시오.</small>
                            </div>
                            
                            <div class="alert alert-danger small p-2 lh-1 mt-2" role="alert" v-if="email_certifi_send_chk==true && email_send_able ==false">
                                <small>이미 사용중인 이메일입니다.<br>다른 이메일을 이용해주세요.</small>
                            </div>
                        </div>
                        <div class="form-group mt-3" v-if="email_certifi_send_chk==true && email_send_able ==true &&email_certifi_chk !=true">
                            <label class="small text-body text-opacity-50">* 인증번호</label>
                            <div class="input-group">
                                <input class="form-control border-gray-400 border-end-0 py-px-12" type="number" required placeholder="인증번호" v-model="certifi_num">
                                <div class="certi_btn btn border border-gray-400 flex-shrink-0 small text-opacity-50" v-if="!email_certifi_chk" @click="ConfirmEmailAuth()">인증확인</div>
                                <button  v-if="email_certifi_chk===true"  class="certi_btn border border-gray-400 text-success btn flex-shrink-0 small border-start-0" disabled="disabled">인증완료 <i class="fal fa-check-circle ms-1"></i></button>
                            </div>
                            <div class="small feedback text-danger" v-if="email_certifi_chk===false">
                                <small>- 인증번호가 틀렸습니다. 다시 입력해 주십시오.</small>
                            </div>
                            <div class="small feedback text-succsess" v-if="email_certifi_chk===true">
                                <small>- 인증이 완료되었습니다.</small>
                            </div>
                        </div>
                        <div class="form-group mt-3">
                            <label class="small text-body text-opacity-50">* 닉네임</label>
                            <div class="input-group">
                                <input class="form-control border-gray-400 py-px-12" type="text" required placeholder="닉네임" v-model="nick" :disabled="CheckNickDisable()">
                                <div class="certi_btn btn border border-start-0 border-gray-400 flex-shrink-0 small  text-opacity-50" @click="CheckNickName()" v-if="nick_dupli_chk !=false">중복확인</div>
                                <div class="certi_btn btn border border-start-0 border-gray-400 flex-shrink-0 small  text-opacity-50" @click="ResetNickName()" v-if="nick_dupli_chk ==false">재입력하기</div>
                            </div>
                            <div class="small feedback text-succsess" v-if="nick_dupli_chk===false">
                                <small>- 사용 가능한 닉네임입니다.</small>
                            </div>
                            <div class="small feedback text-danger" v-if="nick_dupli_chk===true">
                                <small>- 중복된 닉네임입니다.</small>
                            </div>
                        </div>
                       
                        <div class="form-group mt-3">
                            <label class="small text-body text-opacity-50">* 비밀번호</label>
                            <input class="form-control py-px-12" type="password" required placeholder="비밀번호" v-model="password" maxlength="20">
                        </div>
                        <p class="text-body text-opacity-50 mb-3 small">
                            <span class="small">- 영문, 숫자를 혼합하여 6~20자로 사용 가능합니다</span>
                        </p>
                        <div class="form-group mt-3">
                            <label class="small text-body text-opacity-50">* 비밀번호 확인</label>
                            <input class="form-control py-px-12" type="password" required placeholder="비밀번호 확인" v-model="password_confirm" @change="password == password_confirm ? pw_match_chk=true : pw_match_chk=false"  maxlength="20">
                        </div>
                        <p class="text-danger mb-3 small" v-if="pw_match_chk===false">
                            <span class="small">- 비밀번호가 일치하지 않습니다</span>
                        </p>
                        <!-- <div class="form-group mt-3">
                            <label class="small text-body text-opacity-50">* 국가</label>
                            <select class="form-control py-px-12" type="text" required v-model="country">
                                <option v-for="(item,index) in country_list" :key="index" :value="item.code">{{item.name}}</option>
                            </select>
                        </div> -->
                        <div class="form-group mt-3">
                            <label class="small text-body text-opacity-50">* 성명</label>
                            <input class="form-control py-px-12" type="text" required placeholder="성명" v-model="name">
                        </div>
                        <!-- <div class="form-group mt-3">
                            <label class="small text-body text-opacity-50">생년월일</label>
                            <input class="form-control py-px-12" type="date" placeholder="생년월일" v-model="birth">
                        </div>
                        <div class="form-group mt-3">
                            <label class="small text-body text-opacity-50">성별</label>
                            <div class="radio_btn_box">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" id="sex2" v-model="sex" value="2">
                                    <label class="form-control py-px-12" for="sex2">여자</label>
                                </div>
                                <div class="form-check ms-1">
                                    <input class="form-check-input" type="radio" id="sex1" v-model="sex" value="1">
                                    <label class="form-control py-px-12" for="sex1">남자</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mt-3">
                            <label class="small text-body text-opacity-50">핸드폰번호</label>
                            <div class="d-flex">
                                <select class="form-control py-px-12 me-1" style="width: 80px" type="text" required v-model="contact">
                                    <option v-for="(item,index) in contact_list" :key="index" :value="item.value">{{item.name}}</option>
                                </select>
                                <input class="form-control py-px-12" type="number" placeholder="핸드폰번호" v-model="contact2">
                            </div>
                        </div>
                        <div class="form-group mt-3">
                            <label class="small text-body text-opacity-50">직업</label>
                            <select class="form-control py-px-12" type="text" required v-model="job">
                                <option v-for="(item,index) in job_list" :key="index" :value="item.value">{{item.name}}</option>
                            </select>
                        </div>
                        <p class="small mt-4 text-body text-opacity-50">
                            ** 수익금을 받기위해서는 필수로 금융정보를 입력해야 합니다.
                        </p>
                        <div class="form-group mt-3">
                            <label class="small text-body text-opacity-50">여권번호</label>
                            <input class="form-control py-px-12" type="text" placeholder="여권번호" v-model="passport_num">
                        </div>
                        <div class="form-group mt-3">
                            <label class="small text-body text-opacity-50">Swift Code</label>
                            <input class="form-control py-px-12" type="text" placeholder="Swift Code" v-model="swift_code">
                        </div>
                        <div class="form-group mt-3">
                            <label class="small text-body text-opacity-50">은행명</label>
                            <input class="form-control py-px-12" type="text" placeholder="은행명" v-model="bank_name">
                        </div>
                        <div class="form-group mt-3">
                            <label class="small text-body text-opacity-50">계좌번호</label>
                            <input class="form-control py-px-12" type="number" placeholder="계좌번호" v-model="bank_account_num">
                        </div>
                        <div class="form-group mt-3">
                            <label class="small text-body text-opacity-50">주소입력</label>
                            <div class="d-flex mb-1">
                                <input class="form-control py-px-12 me-1" style="width: 90px" type="number" placeholder="우편번호" v-model="addr1">
                                <input class="form-control py-px-12" type="text"  placeholder="예 ) 서울특별시 강남구 강남대로 1길 22" v-model="addr2">
                            </div>
                            <input class="form-control py-px-12" type="text" placeholder="상세주소" v-model="addr3">
                        </div> -->
                        <div class="card mt-4">
                            <div class="form-group pb-3 text-body">
                                <div class="p-3 round_checkbox">
                                    <input type="checkbox" v-model="allSelected" id="terms_chk_all">
                                    <label for="terms_chk_all">
                                        <div class="chk_icon me-2"></div>
                                        <small class="conditions text-truncate">모든 약관에 동의합니다.</small>
                                    </label>
                                </div>
                                <hr class="m-0">
                                <div class="d-flex p-3 pb-0 round_checkbox" v-for="(item, index) in terms" :key="index">
                                    <input type="checkbox"
                                        :id="`checkbox-${index+1}`"
                                        v-model="selectedList"
                                        :value="item.code"
                                        :name="`checkbox-${index+1}`">
                                    <label :for="`checkbox-${index+1}`">
                                        <div class="chk_icon me-2"></div>
                                        <small class="conditions text-truncate">
                                            <span>[{{ShowRequired(item.required)}}]</span>
                                            <span class="ms-1">{{item.title}}</span>
                                        </small>
                                    </label>
                                    <a href="javascript:void(0);" class="ms-auto ps-2" @click="termsPpOn(index)">
                                        <span class="btn btn-sm bg-gray-300 fs-px-12 text-nowrap">약관보기</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    
            
                    <div class=" w-100 py-3">
                        <button class="btn bg-main py-px-12 w-100 text-white" @click="SignUp()">회원가입</button>
                    </div>
                </div>
            </div>
            <div v-if="step == 1">
                <div class="position-absolute start-50 top-50 translate-middle text-center h3 w-100 lh-lg">
                    <!-- <i class="fa-duotone fa-solid fa-microphone-stand fa-3x mx-auto mb-4 d-block text-main"></i> -->
                    <i class="fa-duotone fa-solid fa-message-music fa-3x mx-auto mb-4 d-block text-main"></i>
                    <!-- <i class="fa-duotone fa-solid fa-balloons fa-3x mx-auto mb-4 d-block text-main"></i> -->
            
                    <span class="text-main">{{nick}}</span> 님<br>
                    번다 노래방 회원 가입을<br>
                    <div class="fw-bold">축하합니다.</div>
                </div>
                <div class="position-absolute bottom-0 start-0 w-100 p-3">
                    <router-link to="/" class="w-100 btn py-px-12 bg-main text-white">
                        확인
                    </router-link>
                </div>
            </div>
        </div>
        
        <b-modal v-model="exit_popup" hide-header hide-footer centered size="sm">
            <div class="py-3">
                <!-- <h3 class="text-center mb-3 fw-bold"></h3> -->
                <p class="mb-3 text-center">회원가입을 중단하시겠습니까?<br></p>
            </div>
            <div class="d-flex gap-2 w-100 justify-content-center">
                <button class="btn btn-outline-main w-100" @click="ExitJoin()">중단하기</button>
                <button class="btn btn-main w-100" @click="ExitCancel()">계속하기</button>
            </div>
        </b-modal>

        <div class="infoPp" v-if="termsPp == true">
            <div class="dimm" @click="termsPpOff()"></div>
            <div class="content pt-4">
                <h4 class="mb-3">{{terms[selectedIndex].title}}</h4>
                <pre v-html="terms[selectedIndex].memo"></pre>
            </div>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
const CryptoJS = require("crypto-js");
export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title: '회원가입',

            step: 0,
            exit_popup : false,
            //-----

            nick: '',
            email: '',
            certifi_num: '',
            password: '',
            password_confirm: '',

            country: '',
            name: '',
            birth: '',
            sex: '',
            contact: '',
            contact2: '',
            job: '',

            passport_num: '',
            swift_code: '',
            bank_name: '',
            bank_account_num: '',

            addr1: '',
            addr2: '',
            addr3: '',

            //------

            nick_dupli_chk: null,
            pw_match_chk: null,
            email_certifi_send_chk: false,
            email_certifi_chk: null,

            //------

            country_list: [
                {name: '선택하세요', code: ''},
                {name: 'Republic of Korea (대한민국)', code: 'ko'}
            ],
            contact_list: [
                {name: '선택', value: ''},
                {name: '+82', value: 82}
            ],
            agent : '',
            agent_list : [],
            job_list: [
                {name: '선택하세요', value: ''},
                {name: '직장인', value: 1},
                {name: '금융업', value: 2},
                {name: '공무원', value: 3},
                {name: '전문직', value: 4},
                {name: '자영업', value: 5},
                {name: '프리랜서', value: 6},
                {name: '기타', value: 7}
            ],

            // 약관 동의
            selectedList: [],
            require_list : [],
            terms: [],
            selectedIndex:0,
            infoPp: false,
            termsPp: false,
            email_send_able : false,
            exit_able : false,
        }
    },
    computed:{
        allSelected : {
            get() {
                if( this.terms.length === this.selectedList.length ){
                    return true;
                }else{
                    return false;
                }
            },
            set(value) {
                if(value){ 
                    let _list = [];
                    this.terms.forEach(el => _list.push(el.code))
                    this.selectedList = _list;

                }else{
                    if( this.terms.length === this.selectedList.length ){
                        let _list = [];
                        this.selectedList = _list;
                    }
                }
            },
        }
    },
    mounted(){
        window.addEventListener('beforeunload', this.unLoadEvent);
        this.$EventBus.$emit('HideHeader')
        this.$EventBus.$emit('HideNav')
        this.GetCommonTerm();
    },
    methods:{
        ExitCancel(){
            this.exit_popup = false;
        },
        ExitCancel(){
            this.exit_able = true;
        },
        GetCommonTerm(){
            
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/common/GetCommonTerm',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);

                            this.terms = body.list;
                            let require_list  = [];
                            for (const el of this.terms) {
                                const code = el.code;
                                const required = el.required;
                                if(required =='Y'){
                                    require_list.push(code);
                                }
                                
                                this.require_list  = require_list;
                            }

                        }
                    }
                }
            );
        },
        
        CheckEmailDisable(){
            if(this.email_certifi_send_chk==true && this.email_send_able == true){
                return true;
            }else{
                return false;
            }
        },
        CheckNickDisable(){
            if(this.nick_dupli_chk==false){
                return true;
            }else{
                return false;
            }
        },
        ResetNickName(){
            this.nick = '';
            this.nick_dupli_chk = null;
        },
        CheckNickName(){
            const nick = this.nick;

            if(nick ==''){
                this.$alert("닉네임을 입력해주세요.")
                return false;
            }

            const body = {nick};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/sign/CheckNickName',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.nick_dupli_chk = false;

                        }else if(res.data.code =="100"){
                            this.nick_dupli_chk = true;
                        }
                    }
                }
            );
        },
        ResetEmail(){
            this.email = '';
            this.email_certifi_send_chk = false
            this.email_certifi_chk =  null

        },
        SendEmailAuth(){
            
            const email = this.email.replace(/ /g,"");

            if(email ==''){
                this.$alert("이메일을 입력해주세요.")
                return false;
            }
            const body = {email};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/sign/SendEmailAuth',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.email_certifi_send_chk=true
                            this.email_send_able = true;

                        }else if(res.data.code =="100"){
                            this.email_certifi_send_chk=true;
                            this.email_send_able = false;
                        }
                    }
                }
            );
        },
        ConfirmSignUp(){
            const password = this.password;
            const nick = this.nick;
            const name = this.name;
            const email = this.email;
            const agent = this.agent;
            
            const body = {email,name,nick,password,agent};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/sign/SignUp',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.step = 1
                        }
                    }
                }
            );
        },
        ConfirmEmailAuth(){
            const code = this.certifi_num;
            const email = this.email.replace(/ /g,"");
            const body = {email,code};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.loading = true;
            this.$http.post('/front/sign/ConfirmEmailAuth',{req}).then(
                (res) =>  { 
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.email_certifi_chk=true

                        }else{
                            this.email_certifi_chk=false
                        }
                    }
                }
            );
        },
        ShowRequired: function(required){
            if(required =='Y'){
                return '필수'
            }else{
                return '선택'
            }
        },
        termsPpOn: function(index){
            this.termsPp = true;
            this.selectedIndex=index;
        },
        termsPpOff: function(){
            this.termsPp = false;
        },

        SignUp(){
            const email_certifi_chk = this.email_certifi_chk;
            const nick_dupli_chk = this.nick_dupli_chk;
            const password = this.password;
            const password_confirm = this.password_confirm;
            const name = this.name;

            var reg_pw = /^.*(?=.{6,20})(?=.*[0-9])(?=.*[a-zA-Z]).*$/;

            if(email_certifi_chk !=true){
                this.$alert('이메일 인증후 진행해주세요.');
                return false;
            }

            if(nick_dupli_chk !=false){
                this.$alert('닉네임 중복확인 후 진행해주세요.');
                return false;
            }

            if(password ==''){
                this.$alert('비밀번호를 입력해주세요');
                return false;
            }

            if(password != password_confirm){
                this.$alert('비밀번호를 확인해주세요');
                return false;
            }


            
            
            if(!reg_pw.test(password)){
                this.$alert('비밀번호 형식이 잘못되었습니다.\n'+'(영문,숫자를 혼합하여 6~20자 이내)');
                return false;
            }

            if(name ==''){
                this.$alert('성명을 입력해주세요.');
                return false;
            }
            
            const require_list = this.require_list;
            const selectedList = this.selectedList;

            let check_term = [];
            for (const el of selectedList) {
                let able = false;
                for (const el2 of require_list) {
                    if(able ==false){
                        if(el ==el2){
                            check_term.push('Y');
                        }    
                    }
                }
            }

            if(require_list.length !=check_term.length){
                this.$alert('필수 약관을 확인해주세요.');
                return false;
            }
            

            this.ConfirmSignUp()
        },
        unLoadEvent: function (event) {
            if(this.exit_able ==false){
                this.exit_popup = true;
                return false;
            }

            event.preventDefault();
            event.returnValue = '';
        },  
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
        this.$EventBus.$emit('ShowNav')
        
    }
}
</script>

<style lang="scss" scoped>

    .signup{
        min-height: 100vh;
    }
    .certi_btn{
        line-height: 35px;
        min-width: 90px;
        font-size: 14px;
    }

</style>